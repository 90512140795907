import { useAppSelector } from '@hooks/useAppSelector';
import { NeighborCampaigns } from '@services/hafh/types/generated';

export const useIsNeighborCampaignActiveAndEligible = (
  campaignName: keyof NeighborCampaigns
) => {
  const { campaigns } = useAppSelector((state) => state.campaign);

  const campaign = campaigns?.[campaignName];

  return !!(campaign?.status?.active && campaign?.status?.eligible);
};

export const GLOBAL_PROMOTION_TAB_KEYS = ['taipei', 'others'];

export const GLOBAL_PROMOTION_CAMPAIGN_PROPERTIES = {
  others: [3534, 2425, 2428, 544, 2859],
  taipei: [
    2465, 2449, 2453, 2452, 2451, 2338, 2698, 2422, 2423, 2421, 2424, 1325,
    1337, 319,
  ],
};

export const GLOBAL_PROMOTION_CAMPAIGN_TOPIC_IDS: {
  [key: string]: number;
} = {
  en: 20_783,
  ja: 20_775,
  ko: 20_785,
  'zh-tw': 20_787,
};

export const GLOBAL_PROMOTION_CAMPAIGN_END_DATE = '2024-08-31T23:59:59+0900';

export const GLOBAL_REFERRAL_CAMPAIGN_CODE = '20241009-glb-basic';

export const COIN_BACK_CAMPAIGN_START_DATE = '2024-11-21T12:00:00+0900';

export const COIN_BACK_CAMPAIGN_END_DATE = '2024-12-04T23:59:59+0900';

export const COIN_BACK_PROPERTY_IDS = [
  1065, 1173, 1146, 1148, 1220, 1223, 1250, 2115, 3448, 1131, 2531, 364, 1890,
  870, 876, 2396, 1200, 1194, 1092, 1503,
];

export const COIN_BACK_CAMPAIGN_TOPIC_URL = 'https://www.hafh.com/topics/21655';

export const COIN_BACK_CAMPAIGN_CODE = '202411121_jp_hotel';

export const getIsDuringCoinBackCampaign = () => {
  const now = Date.now();

  return (
    now >= Date.parse(COIN_BACK_CAMPAIGN_START_DATE) &&
    now <= Date.parse(COIN_BACK_CAMPAIGN_END_DATE)
  );
};
